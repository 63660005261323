document.addEventListener('DOMContentLoaded', () => {
  const passwordGroup = document.getElementById('password-group')
  if (!passwordGroup) { return }

  const input = passwordGroup.querySelector('input')
  const viewButton = passwordGroup.querySelector('button')
  viewButton.addEventListener('click', (event) => {
    if (input.type == 'password') {
      input.type = 'text'
      viewButton.innerHTML = '<span class="material-icons-outlined ml-2">visibility_off</span>'
    }
    else {
      input.type = 'password'
      viewButton.innerHTML = '<span class="material-icons-outlined ml-2">visibility</span>'
    }
  })
})
